'use client';

import React, { useState } from 'react';

import { useRole } from '@entities/roles';
import { PageTitle } from '@shared/components/page-title';
import { ContentWrapper } from '@shared/components/content-wrapper';
import { AffiliateDashboard } from '@widgets/affiliate-dashboard';
import { MarketingGeneralReport } from '@widgets/marketing-general-report';
import { OverviewReport } from '@widgets/overview-report';
import { userRole } from '@shared/types';
import { type TMarketingReportType } from '@entities/marketing-report-type/types';
import { MarketingUtmSourceReport } from '@widgets/marketing-utm-source-report';

const PageContentByRole = () => {
	const [marketingReportType, setMarketingReportType] = useState<TMarketingReportType>('general');

	const { role } = useRole();

	switch (role) {
		/**
		 * For now, it's only for marketerVip role
		 */
		case userRole.marketerVip:
			return marketingReportType === 'general' ? (
				<MarketingGeneralReport
					marketingReportType={marketingReportType}
					setMarketingReportType={setMarketingReportType}
				/>
			) : (
				<MarketingUtmSourceReport
					marketingReportType={marketingReportType}
					setMarketingReportType={setMarketingReportType}
				/>
			);
		case userRole.marketer:
			return <MarketingGeneralReport />;
		case userRole.affiliate:
			return <AffiliateDashboard />;
		case userRole.manager:
			return <OverviewReport />;
		default:
			return null;
	}
};

export const MainPage: React.FC = () => {
	const { role, isMarketer } = useRole();

	if (!role) {
		return null;
	}

	return (
		<>
			<PageTitle>{isMarketer ? 'Reports' : 'Dashboard'}</PageTitle>

			<ContentWrapper>
				<PageContentByRole />
			</ContentWrapper>
		</>
	);
};
