'use client';

import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { useTableSorting } from '@shared/hooks/use-table-sorting';
import { useTablePagination } from '@shared/hooks/use-table-pagination';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { mapSortingOrderToBackend } from '@shared/mappers/map-sorting-order-to-backend';
import { ReportContainer, ReportPaper } from '@shared/components/report-container';
import { ReportPagination } from '@shared/components/report-pagination';
import { CommonReportTable } from '@shared/components/report-table';
import { useAidList } from '@entities/aid-list';
import {
	marketingGeneralReportApiHooks,
	type TGeneralMarketingResponse,
} from 'src/shared/api/marketing-general-report';
import { useRole } from '@entities/roles';
import { userRole } from '@shared/types';
import { ReportTypeTabs } from '@entities/marketing-report-type';
import { type TMarketingReportType } from '@entities/marketing-report-type/types';
import { ReportActionsContainer } from '@shared/components/report-filters-container/styles';

import {
	initialFilterState,
	reportDefaultData,
	reportDefaultDateRange,
	reportDefaultSortingOrder,
	reportDefaultSortingType,
	reportDefaultTotals,
	reportTableColumns,
	type TReportColumnId,
} from '../model';
import { mapTotalsFromBackend } from '../lib/map-totals-from-backend';
import { mapDataToCellData } from '../lib/map-data-to-cell-data';
import { reportFilterReducer } from '../lib/report-filter-reducer';

import { DownloadButton } from './download-button';
import { ReportFilters } from './report-filters';

type TMarketingGeneralReportProps = {
	marketingReportType?: TMarketingReportType;
	setMarketingReportType?: React.Dispatch<React.SetStateAction<TMarketingReportType>>;
};

export const MarketingGeneralReport: React.FC<TMarketingGeneralReportProps> = ({
	marketingReportType,
	setMarketingReportType,
}) => {
	const { role } = useRole();

	const [reportData, setReportData] = useState<TGeneralMarketingResponse | undefined>();

	const { aidList, isAidListSuccess, initialAidsSet } = useAidList(true);

	const [filterState, dispatchFilterAction] = useReducer<typeof reportFilterReducer>(
		reportFilterReducer,
		null,
	);

	const [selectedAidList, setSelectedAidList] = useState<number[]>();

	const [dateRange, setDateRange] = useState<TDateRangeValue>(reportDefaultDateRange);

	const { sorting, handleSortingChange } = useTableSorting<TReportColumnId>({
		defaultSortingOrder: reportDefaultSortingOrder,
	});

	const { page, setPage, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
		useTablePagination({
			pageRowsCount: 10,
		});

	useEffect(() => {
		if (isAidListSuccess && aidList) {
			setSelectedAidList(aidList.map(({ id }) => id));
		}
	}, [aidList, isAidListSuccess]);

	const { mutate, isLoading, isError } = marketingGeneralReportApiHooks.useGeneral();

	const handleGetReportData = useCallback(() => {
		if (!Array.isArray(aidList)) {
			return;
		}

		mutate(
			{
				aids: selectedAidList ?? Array.from(initialAidsSet),
				...filterState,
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
				orderBy: reportDefaultSortingType,
				orderDirection: mapSortingOrderToBackend(sorting.date),
			},
			{
				onSuccess: setReportData,
			},
		);
	}, [aidList, dateRange, filterState, initialAidsSet, mutate, selectedAidList, sorting.date]);

	const invalidate = () => Promise.resolve().then(handleGetReportData);

	// TODO Check if we could set right in the query
	const data = useMemo(() => reportData?.data ?? reportDefaultData, [reportData?.data]);

	const totals = useMemo(() => reportData?.totals ?? reportDefaultTotals, [reportData?.totals]);

	/**
	 * Subscribe on filters change to update the report
	 */
	useEffect(() => {
		handleGetReportData();
	}, [
		dateRange,
		filterState,
		handleGetReportData,
		initialAidsSet,
		mutate,
		selectedAidList,
		sorting.date,
	]);

	useEffect(() => {
		setPage(0);
	}, [reportData, setPage]);

	// Specific case when manager user role  has chosen no aids
	const noAidsSelected =
		!aidList || !Array.isArray(selectedAidList) || selectedAidList.length === 0;

	const cellData = useMemo(() => mapDataToCellData(data), [data]);

	const totalsData = useMemo(() => mapTotalsFromBackend(totals), [totals]);

	return (
		<ReportContainer>
			<ReportPaper>
				<ReportActionsContainer
					sx={{
						padding: '0.5rem 1rem',
						borderBottom: 'none!important',
						justifyContent: 'space-between',
					}}
				>
					{role === userRole.marketerVip && (
						<ReportTypeTabs
							marketingReportType={marketingReportType}
							setMarketingReportType={setMarketingReportType}
						/>
					)}

					<DownloadButton
						aids={selectedAidList ?? Array.from(initialAidsSet)}
						dateRange={dateRange}
						filters={filterState ?? initialFilterState}
					/>
				</ReportActionsContainer>

				<ReportFilters
					dateRange={dateRange}
					setDateRange={setDateRange}
					setSelectedAids={setSelectedAidList}
					selectedFilters={filterState}
					setFilters={dispatchFilterAction}
					filtersOptionsData={reportData?.filter}
				/>

				<CommonReportTable<TReportColumnId>
					data={cellData}
					totals={totalsData}
					page={page}
					rowsPerPage={rowsPerPage}
					sorting={sorting}
					onSortingChange={handleSortingChange}
					columns={reportTableColumns}
					isLoading={isLoading}
					isError={isError}
					invalidate={invalidate}
					isEmpty={noAidsSelected}
					scrollable
				/>
			</ReportPaper>

			<ReportPagination
				totalCount={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</ReportContainer>
	);
};
