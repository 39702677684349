import {
	reportConfig,
	type TReportFilter,
	type TReportFilterAction,
} from '@widgets/marketing-utm-source-report/model';

export const reportFilterReducer = (
	state: TReportFilter | null,
	action: TReportFilterAction,
): TReportFilter | null => {
	switch (action.type) {
		case 'SET_VALUES': {
			return {
				...(state ?? reportConfig.filters.initialState),
				...action.payload,
			};
		}
		default: {
			return state ?? reportConfig.filters.initialState;
		}
	}
};
