import { useState } from 'react';

import { CustomDateRangePicker } from '@shared/components/date-range-calendar';
import {
	DateRangeFilterContainer,
	ReportFiltersContainer,
} from '@shared/components/report-filters-container';
import { AidList } from '@entities/aid-list';
import { mapSourcesFromBackend } from '@shared/mappers/map-sources-from-backend';
import { DropdownSelect } from '@shared/components/dropdown-select';
import { useSetOptionsOnce } from '@shared/hooks/use-set-options-once';

import { type TReportFilter, type TReportFilterNames } from '../../model';

import { type TReportFiltersProps } from './types';

type TFilterEntry = [keyof TReportFilter, (string | null)[]];

const filterNameByKey: Record<keyof TReportFilter, string> = {
	source: 'Sources',
	utmSource: 'UTM Source',
	utmMedium: 'Medium',
	utmCampaign: 'Campaign',
	utmContent: 'Content',
};

export const ReportFilters: React.FC<TReportFiltersProps> = ({
	dateRange,
	setDateRange,
	setSelectedAids,
	selectedFilters,
	setFilters,
	filtersOptionsData,
}) => {
	// TODO: можно вынести выше и хранить в одном сосотянии, просто опции помечать выбрана/не выбрана
	const [filterOptions, setFilterOptions] = useState<TReportFilter>();

	const getSelectHandler = (field: TReportFilterNames) => (selectedValue: (string | null)[]) => {
		setFilters({
			type: 'SET_VALUES',
			payload: {
				[field]: selectedValue,
			},
		});
	};

	useSetOptionsOnce(filtersOptionsData, (payload) => {
		if (!payload) {
			return;
		}
		setFilterOptions(payload);
		setFilters({ type: 'SET_VALUES', payload });
	});

	return (
		<ReportFiltersContainer>
			<DateRangeFilterContainer>
				<CustomDateRangePicker value={dateRange} setValue={setDateRange} />
			</DateRangeFilterContainer>

			<AidList onSelect={setSelectedAids} theme="classic" />

			{filterOptions &&
				(Object.entries(filterOptions) as unknown as TFilterEntry[]).map(
					([filterKey, filterOptionsList]) => (
						<DropdownSelect<string | null>
							key={filterKey}
							dropdownValue={selectedFilters?.[filterKey] ?? filterOptions[filterKey]}
							onDropdownSelect={getSelectHandler(filterKey)}
							options={mapSourcesFromBackend(
								filterOptionsList,
								filterNameByKey[filterKey].toLowerCase(),
							)}
							isMultiple
							forcedPlaceholder={filterNameByKey[filterKey]}
							innerTitle={filterNameByKey[filterKey]}
							resetButton
							baseHeaderElement
						/>
					),
				)}
		</ReportFiltersContainer>
	);
};
