import z from 'zod';
import { makeParameters, makeErrors } from '@zodios/core';

export const generalReportParams = makeParameters([
	{
		name: 'payload',
		type: 'Body',
		schema: z.object({
			dateFrom: z.string().datetime({ offset: true }),
			dateTo: z.string().datetime({ offset: true }),
			source: z.array(z.string().nullable()).optional(),
			aids: z.array(z.number()).optional(),
			utmSource: z.array(z.string().nullable()).optional(),
			utmMedium: z.array(z.string().nullable()).optional(),
			utmCampaign: z.array(z.string().nullable()).optional(),
			utmContent: z.array(z.string().nullable()).optional(),
			orderBy: z.string().optional(),
			orderDirection: z.union([z.literal('ASC'), z.literal('DESC')]).optional(),
		}),
	},
]);

export const generalReportResponse = z.object({
	data: z.array(
		z.object({
			aid: z.number(),
			date: z.string().datetime({ offset: true }),
			visitsCount: z.number(),
			visitLeadConversion: z.number(),
			leadsCount: z.number(),
			leadClickConversion: z.number(),
			clicksCount: z.number(),
			revenue: z.number(),
			epl: z.number(),
			epc: z.number(),
		}),
	),
	filter: z.object({
		source: z.array(z.string().nullable()),
		utmSource: z.array(z.string().nullable()),
		utmMedium: z.array(z.string().nullable()),
		utmCampaign: z.array(z.string().nullable()),
		utmContent: z.array(z.string().nullable()),
	}),
	totals: z.object({
		visitsCount: z.number().default(0),
		visitLeadConversion: z.number().default(0),
		leadsCount: z.number().default(0),
		leadClickConversion: z.number().default(0),
		clicksCount: z.number().default(0),
		revenue: z.number().default(0),
		epl: z.number().default(0),
		epc: z.number().default(0),
	}),
});

export const detailedReportParams = makeParameters([
	{
		name: 'dateFrom',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'dateTo',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'aids',
		type: 'Query',
		schema: z.array(z.number()).optional(),
	},
	{
		name: 'aids',
		type: 'Query',
		schema: z.array(z.number()).optional(),
	},
	{
		name: 'filename',
		type: 'Query',
		schema: z.string().nullable(),
	},
	{
		name: 'uploadUrl',
		type: 'Query',
		schema: z.string().optional(),
	},
	{
		name: 'offset',
		type: 'Query',
		schema: z.number(),
	},
]);

export const detailedReportResponse = z.object({
	filename: z.string().nullable(),
	offset: z.number(),
	uploadUrl: z.string().nullable(),
});

export const commonError = makeErrors([
	{
		status: 'default',
		schema: z.object({
			message: z.string(),
		}),
	},
]);
