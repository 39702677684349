import type React from 'react';

import { Tab, TabsContainer } from '@entities/marketing-report-type/style';
import { type TMarketingReportType } from '@entities/marketing-report-type/types';

type TReportTypeTabsProps = {
	marketingReportType?: TMarketingReportType;
	setMarketingReportType?: React.Dispatch<React.SetStateAction<TMarketingReportType>>;
};

export const ReportTypeTabs: React.FC<TReportTypeTabsProps> = ({
	marketingReportType,
	setMarketingReportType,
}) => (
	<TabsContainer>
		<Tab
			type="button"
			$active={marketingReportType === 'general'}
			onClick={() => setMarketingReportType?.('general')}
		>
			General
		</Tab>

		<Tab
			type="button"
			$active={marketingReportType === 'utmSource'}
			onClick={() => setMarketingReportType?.('utmSource')}
		>
			UTM Source
		</Tab>
	</TabsContainer>
);
