import { formatISO, subWeeks } from 'date-fns';

import { type TReportSorting } from '@shared/components/report-table/types';
import { utmSourceReportResponse } from '@shared/api/marketing-utm-source-report';

import {
	type TReportColumnId,
	type TReportFilter,
	type TReportFilterNames,
	type TReportConfig,
} from './types';

const reportResponseSchema = utmSourceReportResponse;

export const reportFilterNames = Object.keys(
	reportResponseSchema.shape.filter.shape,
) as TReportFilterNames[];

export const reportConfig: TReportConfig = {
	filters: {
		defaultDateRange: [formatISO(subWeeks(new Date(), 1)), formatISO(new Date())],
		names: reportFilterNames,
		initialState: reportFilterNames.reduce<TReportFilter>(
			(acc, filterName) => ({
				...acc,
				[filterName]: [],
			}),
			{} as TReportFilter,
		),
	},
	data: {
		columns: [
			{
				id: 'aid',
				label: 'AID',
				width: 100,
				align: 'center',
				pinned: true,
				pinOffset: 0,
				className: 'last-pinned',
			},
			{
				id: 'utmSource',
				label: 'UTM Source',
				width: 140,
				align: 'left',
			},
			{
				id: 'utmMedium',
				label: 'Medium',
				width: 123,
				align: 'left',
			},
			{
				id: 'utmCampaign',
				label: 'Campaign',
				width: 208,
				align: 'left',
			},
			{
				id: 'utmContent',
				label: 'Content',
				width: 208,
				align: 'left',
			},
			{
				id: 'visitsCount',
				label: 'Visits',
				width: 123,
				align: 'right',
			},
			{
				id: 'visitLeadConversion',
				label: 'CR, %',
				width: 123,
				align: 'right',
			},
			{
				id: 'leadsCount',
				label: 'Leads',
				width: 123,
				align: 'right',
			},
			{
				id: 'leadClickConversion',
				label: 'CR, %',
				width: 123,
				align: 'right',
			},
			{
				id: 'clicksCount',
				label: 'Listing Clicks',
				width: 123,
				align: 'right',
			},
			{
				id: 'revenue',
				label: 'Revenue, $',
				width: 123,
				align: 'right',
			},
			{
				id: 'epl',
				label: 'EPL, $',
				width: 123,
				align: 'right',
			},
			{
				id: 'epc',
				label: 'EPC, $',
				width: 123,
				align: 'right',
			},
		],
		defaultData: [],
		defaultTotals: reportResponseSchema.shape.totals.parse({}),
	},
	sorting: {
		column: 'aid',
		order: Object.fromEntries(
			Object.entries(reportResponseSchema.shape.data.element.shape).map(([key]) => [key, 'none']),
		) as TReportSorting<TReportColumnId>,
	},
};
