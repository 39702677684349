import { type TRowData } from '@shared/components/report-table/types';
import { getAmountFromCents } from '@shared/utils/number';
import { generateUuid } from '@shared/utils/id';

import { type TReportColumnId, type TReportData } from '../model';

export const mapDataToCellData = (data: TReportData): TRowData<TReportColumnId>[] =>
	data.map((rowData) => ({
		id: generateUuid(),
		cells: {
			aid: {
				value: String(rowData.aid),
			},
			utmSource: {
				value: rowData.utmSource,
			},
			utmMedium: {
				value: rowData.utmMedium,
			},
			utmCampaign: {
				value: rowData.utmCampaign,
			},
			utmContent: {
				value: rowData.utmContent,
			},
			visitsCount: {
				value: String(rowData.visitsCount),
			},
			visitLeadConversion: {
				value: String(rowData.visitLeadConversion),
			},
			leadsCount: {
				value: String(rowData.leadsCount),
			},
			leadClickConversion: {
				value: String(rowData.leadClickConversion),
			},
			clicksCount: {
				value: String(rowData.clicksCount),
			},
			revenue: {
				value: getAmountFromCents(rowData.revenue).toFixed(2),
			},
			epl: {
				value: getAmountFromCents(rowData.epl).toFixed(2),
			},
			epc: {
				value: getAmountFromCents(rowData.epc).toFixed(2),
			},
		},
	}));
