import z from 'zod';
import { makeParameters, makeErrors } from '@zodios/core';

export const utmSourceReportParams = makeParameters([
	{
		name: 'payload',
		type: 'Body',
		schema: z.object({
			dateFrom: z.string().datetime({ offset: true }),
			dateTo: z.string().datetime({ offset: true }),
			source: z.array(z.string().nullable()).optional(),
			aids: z.array(z.number()).optional(),
			utmSource: z.array(z.string().nullable()).optional(),
			utmMedium: z.array(z.string().nullable()).optional(),
			utmCampaign: z.array(z.string().nullable()).optional(),
			utmContent: z.array(z.string().nullable()).optional(),
			orderBy: z.string().optional(),
			orderDirection: z.union([z.literal('ASC'), z.literal('DESC')]).optional(),
		}),
	},
]);

export const utmSourceReportResponse = z.object({
	data: z.array(
		z.object({
			aid: z.number(),
			clicksCount: z.number(),
			epc: z.number(),
			epl: z.number(),
			leadClickConversion: z.number(),
			leadsCount: z.number(),
			revenue: z.number(),
			utmCampaign: z.string(),
			utmContent: z.string(),
			utmMedium: z.string(),
			utmSource: z.string(),
			visitsCount: z.number(),
			visitLeadConversion: z.number(),
		}),
	),
	filter: z.object({
		source: z.array(z.string().nullable()),
		utmSource: z.array(z.string().nullable()),
		utmMedium: z.array(z.string().nullable()),
		utmCampaign: z.array(z.string().nullable()),
		utmContent: z.array(z.string().nullable()),
	}),
	totals: z.object({
		clicksCount: z.number().default(0),
		epc: z.number().default(0),
		epl: z.number().default(0),
		leadClickConversion: z.number().default(0),
		leadsCount: z.number().default(0),
		revenue: z.number().default(0),
		visitLeadConversion: z.number().default(0),
		visitsCount: z.number().default(0),
	}),
});

export const commonError = makeErrors([
	{
		status: 'default',
		schema: z.object({
			message: z.string(),
		}),
	},
]);
