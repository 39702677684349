import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { commonError, utmSourceReportParams, utmSourceReportResponse } from './schemas';

export const marketingUtmSourceReport = makeApi([
	{
		alias: 'utmSource',
		method: 'post',
		path: '/cabinet/analytics/report/utm-marketing/',
		response: utmSourceReportResponse,
		parameters: utmSourceReportParams,
		errors: commonError,
	},
	{
		alias: 'utmSourceCsv',
		method: 'post',
		path: '/cabinet/analytics/report/utm-marketing/download/',
		parameters: utmSourceReportParams,
		response: z.string(),
		errors: commonError,
	},
]);
