import { useCallback, useEffect, useState } from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from 'next/link';

import { Button } from '@shared/components/button';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import {
	marketingGeneralReportApiClient,
	marketingGeneralReportApiHooks,
} from 'src/shared/api/marketing-general-report';
import { getExportFileName, handleDownloadFile } from '@shared/utils/file';

import { type TReportFilter } from '../../model';

import { LinkTypography, ListItemText, Menu, MenuItem } from './styles';

const pendingOffsetLimit = 1_000_000;

export const DownloadButton = ({
	dateRange,
	aids,
	filters,
}: {
	aids?: number[];
	dateRange: TDateRangeValue;
	filters: TReportFilter;
}) => {
	const [offset, setOffset] = useState(0);

	const [filename, setFilename] = useState<string | null>(null);

	const [uploadUrl, setUploadUrl] = useState<string | undefined>();

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const [isError, setError] = useState<boolean>(false);

	const [isDetailedCsvPending, setIsDetailedCsvPending] = useState<boolean>(false);

	const isDownloadMenuOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const cleanupState = () => {
		setOffset(0);
		setFilename(null);
		setIsDetailedCsvPending(false);
		handleClose();
	};

	const fetchUtmCsv = useCallback(async () => {
		const response = await marketingGeneralReportApiClient.detailedCsv({
			queries: {
				aids,
				offset,
				filename,
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
			},
		});

		if (response.uploadUrl) {
			cleanupState();
			window.open(response.uploadUrl, '_blank');
			setUploadUrl(response.uploadUrl);
			return;
		}

		if (offset >= pendingOffsetLimit) {
			setError(true);
			cleanupState();
			return;
		}

		if (typeof response.offset !== 'number') {
			setError(true);
			cleanupState();
			return;
		}

		if (typeof response.filename === 'string') {
			setFilename(response.filename);
		}

		setOffset(response.offset);
	}, [aids, cleanupState, dateRange, filename, offset]);

	useEffect(() => {
		// Cyclic download requests
		if (offset > 0) {
			fetchUtmCsv().catch(() => {
				setError(true);
			});
		}
	}, [fetchUtmCsv, offset]);

	const {
		mutate: getGeneralReport,
		isLoading: isLoadingGeneralReport,
		isIdle: isIdleGeneralReport,
	} = marketingGeneralReportApiHooks.useGeneralCsv();

	const isReportLoading = isDetailedCsvPending || (isLoadingGeneralReport && !isIdleGeneralReport);

	const isButtonDisabled =
		dateRange.includes(null) || typeof aids === 'undefined' || aids.length === 0 || isReportLoading;

	const handleCloseErrorToast = (
		_?: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => {
		if (reason !== 'clickaway') {
			setError(false);
		}
	};

	const handleCloseSuccessToast = (
		_?: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => {
		if (reason !== 'clickaway') {
			setUploadUrl(undefined);
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleClick}
				sx={{ borderColor: '#E5E1E9' }}
				disabled={isButtonDisabled}
				startIcon={isReportLoading ? <SyncRoundedIcon /> : <ArrowOutwardIcon />}
			>
				Export Report as CSV
			</Button>

			<Menu anchorEl={anchorEl} open={isDownloadMenuOpen} onClose={handleClose}>
				<MenuItem
					disabled={isButtonDisabled}
					onClick={() => {
						getGeneralReport(
							{
								aids,
								dateFrom: mapDateToBackend(dateRange[0]),
								dateTo: mapDateToBackend(dateRange[1]),
								...filters,
							},
							{
								onSuccess: (content) => {
									const blob = new Blob([`\uFEFF${content}`], { type: 'text/csv;charset=utf-8' });

									handleDownloadFile(blob, getExportFileName(dateRange, 'general'));
								},
								onError: () => setError(true),
							},
						);
					}}
				>
					<ListItemText>General</ListItemText>
				</MenuItem>

				<MenuItem
					disabled={isButtonDisabled}
					onClick={() => {
						setIsDetailedCsvPending(true);
						fetchUtmCsv().catch(() => {
							setError(true);
						});
					}}
				>
					<ListItemText>Detailed</ListItemText>
				</MenuItem>
			</Menu>

			<Snackbar
				open={isError}
				autoHideDuration={2000}
				onClose={handleCloseErrorToast}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleCloseErrorToast}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>

			<Snackbar
				open={typeof uploadUrl !== 'undefined'}
				autoHideDuration={10000}
				onClose={handleCloseSuccessToast}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleCloseSuccessToast}
					severity="success"
					variant="filled"
					sx={(theme) => ({
						color: theme.palette.primary.contrastText,
					})}
				>
					{uploadUrl && (
						<LinkTypography>
							Your report is ready!{' '}
							<Link href={uploadUrl} target="_blank">
								Click to download
							</Link>
						</LinkTypography>
					)}
				</Alert>
			</Snackbar>
		</>
	);
};
