import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { BASE_URL } from '@shared/api/constants';
import { axiosInstance } from '@shared/api/axios-instance';
import { marketingUtmSourceReport } from '@shared/api/marketing-utm-source-report/api';

export { type TUtmSourceMarketingResponse } from './types';
export { utmSourceReportResponse } from './schemas';

export const marketingUtmSourceReportApiClient = new Zodios(BASE_URL, marketingUtmSourceReport, {
	axiosInstance,
});

export const marketingUtmSourceReportApiHooks = new ZodiosHooks(
	'statistics',
	marketingUtmSourceReportApiClient,
);
