import { styled } from '@mui/material';

export const TabsContainer = styled('div')(() => ({
	display: 'inline-flex',
	flexFlow: 'row nowrap',
	justifyContent: 'flex-start',
	alignItems: 'center',

	'& > *:not(:last-child)': {
		marginRight: '0.5rem',
	},
}));

export const Tab = styled('button')<{
	$active?: boolean;
}>(({ theme, $active }) => ({
	margin: 0,
	padding: '0 0.5rem',
	border: 0,
	outline: 0,
	background: 'transparent',

	...theme.typography.messageTitle,
	textAlign: 'center',
	color: $active ? theme.palette.common.black : theme.palette.custom.component.border,

	cursor: 'pointer',
}));
