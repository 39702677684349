import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import { Button } from '@shared/components/button';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { marketingUtmSourceReportApiHooks } from '@shared/api/marketing-utm-source-report';
import { getExportFileName, handleDownloadFile } from '@shared/utils/file';

import { type TReportFilter } from '../../model';

export const DownloadButton = ({
	dateRange,
	aids,
	filters,
}: {
	aids?: number[];
	dateRange: TDateRangeValue;
	filters: TReportFilter;
}) => {
	const {
		mutate: getUtmSourceCsv,
		isLoading: isUtmSourceCsvLoading,
		isIdle: isUtmSourceCsvIdle,
	} = marketingUtmSourceReportApiHooks.useUtmSourceCsv();

	const isCsvLoading = isUtmSourceCsvLoading && !isUtmSourceCsvIdle;

	const isButtonDisabled =
		dateRange.includes(null) || typeof aids === 'undefined' || aids.length === 0 || isCsvLoading;

	const handleCsvSuccessLoad = (content: string) => {
		const blob = new Blob([`\uFEFF${content}`], { type: 'text/csv;charset=utf-8' });

		handleDownloadFile(blob, getExportFileName(dateRange, 'utmSource'));
	};

	const handleCsvDownload = () => {
		getUtmSourceCsv(
			{
				aids,
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
				...filters,
			},
			{
				onSuccess: handleCsvSuccessLoad,
			},
		);
	};

	return (
		<Button
			variant="outlined"
			onClick={handleCsvDownload}
			sx={{ borderColor: '#E5E1E9' }}
			disabled={isButtonDisabled}
			startIcon={isCsvLoading ? <SyncRoundedIcon /> : <ArrowOutwardIcon />}
		>
			Export Report as CSV
		</Button>
	);
};
