export const handleDownloadFile = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(blob);

	const link = document.createElement('a');

	Object.assign(link, {
		href: url,
		download: filename,
	});

	document.body.appendChild(link);

	link.click();

	document.body.removeChild(link);

	window.URL.revokeObjectURL(url);
};
