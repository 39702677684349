import { makeApi } from '@zodios/core';
import { z } from 'zod';

import {
	commonError,
	generalReportParams,
	generalReportResponse,
	detailedReportParams,
	detailedReportResponse,
} from './schemas';

export const marketingGeneralReport = makeApi([
	{
		alias: 'general',
		method: 'post',
		path: '/cabinet/analytics/report/general-marketing/',
		response: generalReportResponse,
		parameters: generalReportParams,
		errors: commonError,
	},
	{
		alias: 'generalCsv',
		method: 'post',
		path: '/cabinet/analytics/report/general-marketing/download/',
		parameters: generalReportParams,
		response: z.string(),
		errors: commonError,
	},
	{
		alias: 'detailedCsv',
		method: 'get',
		path: '/cabinet/analytics/report/detailed/download/',
		parameters: detailedReportParams,
		response: detailedReportResponse,
		errors: commonError,
	},
]);
