import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { BASE_URL } from '@shared/api/constants';
import { axiosInstance } from '@shared/api/axios-instance';

import { marketingGeneralReport } from './api';

export { type TGeneralMarketingResponse } from './types';
export { generalReportResponse } from './schemas';

export const marketingGeneralReportApiClient = new Zodios(BASE_URL, marketingGeneralReport, {
	axiosInstance,
});

export const marketingGeneralReportApiHooks = new ZodiosHooks(
	'statistics',
	marketingGeneralReportApiClient,
);
